/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.alert-radio-icon.sc-ion-alert-md {
    left: 14px;
}

.alert-radio-label.sc-ion-alert-md {
    padding-inline-start: 22px !important;
}

.alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
    left: 14px;
    font-size: 11px !important;
}

.alert-radio-label.sc-ion-alert-ios {
    font-size: 11px !important;
}

.split-pane-visible >.split-pane-side {
    min-width: 300px!important;
    max-width: 300px!important;
  }

.alert-title.sc-ion-alert-md{
    font-size: 18px;  
}

.alertTitleWrap {
    white-space: pre-wrap;
}

